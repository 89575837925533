<template>
  <div id="app">
    <!-- 
        贵州
        公众号绑定记录
        PublicBindingRecord
     -->
    <div class="container">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input v-model="name" placeholder="请输入姓名" clearable></el-input>
        </el-col>
        <el-col :span="6">
          <el-input v-model="phone" placeholder="请输入手机号" clearable></el-input>
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="searchInfo">搜索</el-button>
        </el-col>
      </el-row>

      <div class="content">
        <el-table :data="infoList" style="width: 100%">
          <el-table-column type="index" label="序号" width="80" align="center">
          </el-table-column>
          <el-table-column prop="name" label="姓名" align="center">
          </el-table-column>
          <el-table-column prop="phone" label="电话" align="center">
          </el-table-column>
          <el-table-column prop="createTime" label="绑定时间" align="center">
          </el-table-column>
        </el-table>
      </div>
      <el-pagination :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" @current-change="getCrtList"></el-pagination>

    </div>
  </div>
</template>

<script>

import User from '@/api/manageApi/User'
export default {
  data() {
    return {
      current: 1,
      limit: 10,
      total: 0,
      name: '',   // 姓名
      phone: '',  // 电话


      infoList: [],


    }
  },

  created() {
    this.getUserInfo()
  },

  methods: {
    async getUserInfo() {
      const { data: info } = await User.getWechatUserInfoList(this.current, this.limit, this.name, this.phone)
      console.log(info);
      this.total = info.data.total
      this.infoList = info.data.list;
    },

    searchInfo() {
      this.getUserInfo()
    },

    getCrtList() {
      this.getUserInfo()
    }
  }
}

</script>

<style lang="scss" scoped>
.container {
  margin: 10px 0;
  width: 100vw;
  height: 100%;
  box-sizing: border-box;

  .content {
    margin: 10px 0;
  }
}
</style>