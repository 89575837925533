import { render, staticRenderFns } from "./PublicBindingRecord.vue?vue&type=template&id=486429da&scoped=true&"
import script from "./PublicBindingRecord.vue?vue&type=script&lang=js&"
export * from "./PublicBindingRecord.vue?vue&type=script&lang=js&"
import style0 from "./PublicBindingRecord.vue?vue&type=style&index=0&id=486429da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486429da",
  null
  
)

export default component.exports